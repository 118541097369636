import React, { useCallback, useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const Confetti = ({ action }: { action: boolean }) => {
  const refAnimationInstance = useRef<((opts: any) => void) | null>(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    // eslint-disable-next-line no-unused-expressions
    if (typeof refAnimationInstance.current === 'function') {
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.5, x: 0.5 },
        colors: ['#FF7B7B', '#8247E5', '#306FFC', '#ff0'],
        particleCount: Math.floor(200 * particleRatio),
      });
    }
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    if (action) {
      fire();
    }
  }, [action]);

  return (
    <ReactCanvasConfetti
      useWorker={false}
      refConfetti={getInstance}
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  );
};

export default Confetti;
