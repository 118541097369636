import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import history from 'utils/history';
import Button from 'src/components/Button';
import useKycProfile from 'src/customer/components/kyc/Profile/hooks';
import Confetti from 'src/customer/components/Layout/Confetti';
import LoadingStatic from 'components/Loading/Static';
import {
  registerInviteCode,
  removeInviteCode,
} from 'customer/components/iam/InviteCode/service';
import { Profile } from '../../kyc/Profile/types';
import { isDraft } from '../../kyc/Profile/util';
import useLogin from '../Login/hooks';
import { AuthContext } from '../Login/types';
import splashImg from './splash.svg';
import s from './Unlocking.less';

const Unlocking = ({
  profileId,
  inviteCode,
  onSuccess,
  close,
}: {
  profileId: string;
  inviteCode: string;
  onSuccess?: (auth: AuthContext) => void;
  close: () => void;
}) => {
  useStyles(s);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInviteRegistered, setIsInviteRegistered] = useState<boolean>();
  const { authContext, read } = useLogin();
  const { profile, read: readKycProfile } = useKycProfile();

  const getUpdatedUser = async () => {
    try {
      const userId = authContext?.userId;
      if (userId) {
        await read(userId);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const verifyCode = async () => {
    try {
      await registerInviteCode(inviteCode);
      setIsInviteRegistered(true);
      getUpdatedUser();
    } catch (e) {
      setIsInviteRegistered(false);
      console.error(e);
    } finally {
      removeInviteCode();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkProfile = async () => {
      let kycProfile: Profile | undefined = profile;

      if (!kycProfile) {
        const result = await readKycProfile(profileId);
        if (result) {
          kycProfile = result as Profile;
        }
      }

      if (kycProfile) {
        if (isDraft(kycProfile)) {
          verifyCode();
        } else {
          removeInviteCode();
          close();
        }
      } else {
        console.error('Profile not found');
      }
    };

    checkProfile();
  }, []);

  const success = () => {
    if (typeof onSuccess === 'function') {
      onSuccess(authContext);
    } else if (history) {
      history.push(`/profiles`);
    }
  };

  return (
    <div className={s.container}>
      <div
        className={s.greetingPanel}
        style={{ backgroundImage: `url(${splashImg})` }}
      >
        <div className={s.greetingText}>
          {isLoading && (
            <>
              <h4 className={s.greetingTitle}>Verifying Invite Code</h4>
              <LoadingStatic dialog="" />
            </>
          )}
          {!isLoading && isInviteRegistered && (
            <>
              <h4 className={s.greetingTitle}>Access granted!</h4>
              <p className={s.greetingSub}>
                Congratulations! You&apos;ve successfully unlocked the Monerium
                app. The next step is a breeze – we&apos;d love to learn a
                little about you.
                <br />
                <br />
                Once approved, you&apos;ll be all set to receive your personal,
                ultra-fast IBAN and begin transacting with ease.
              </p>
              <Button
                style={{ maxWidth: '250px' }}
                info
                onClick={() => success()}
              >
                Continue
              </Button>
              <Confetti action />
            </>
          )}
          {!isLoading && isInviteRegistered === false && (
            <>
              <h4 className={cx(s.greetingTitle, s.warning)}>
                Invalid Invite Code
              </h4>
              <p className={s.greetingSub}>
                Oops! It looks like the invite code you used isn&apos;t valid.
                This could be because it&apos;s already been used or isn&apos;t
                active anymore. Please check with the community member who
                provided you with the code for further assistance.
              </p>
              <Button style={{ maxWidth: '250px' }} info onClick={close}>
                Close
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Unlocking;
