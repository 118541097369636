// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WX7DY{display:-ms-flexbox;display:flex;position:relative;-ms-flex-pack:center;justify-content:center}.q6Zj0{background-attachment:scroll;background-color:#fff;background-position:50% 0;background-repeat:no-repeat;background-size:auto;border-radius:20px;-webkit-box-shadow:0 2px 3px rgba(0,0,0,.15);box-shadow:0 2px 3px rgba(0,0,0,.15);min-height:280px;padding:280px 30px 30px;text-align:center;width:400px}.UVnDu{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.UVnDu .kkPAv{color:var(--form-color-error)}.K93Q1{font-weight:900;text-align:center}.Ku_7g,.K93Q1{margin-bottom:30px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `WX7DY`,
	"greetingPanel": `q6Zj0`,
	"greetingText": `UVnDu`,
	"warning": `kkPAv`,
	"greetingTitle": `K93Q1`,
	"greetingSub": `Ku_7g`
};
module.exports = ___CSS_LOADER_EXPORT___;
